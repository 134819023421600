import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import avatar from '../images/avatar.png'

const Header = props => (
  <header
    style={{
      background: `white`,
      marginBottom: `2rem`,
    }}
  >
    <div
      style={{
        display: `flex`,
        margin: `0 auto`,
        maxWidth: 865,
        padding: `36px 20px`,
        alignItems: `center`,
      }}
    >
      <div>
        <Link getProps={isActive} {...props} to="/">
          <img src={avatar}
            style={{
              display: `flex`,
              margin: `0 auto`,
              height: `75px`,
            }}
            alt="Blog logo"
          />
        </Link>
      </div>
      {/*<div
        style={{
          marginLeft: `auto`,
        }}
      >
        <Link getProps={isActive} {...props}
          to="/"
          style={{
            marginRight: `28px`,
            textDecoration: `none`,
          }}
          activeStyle={{
            color: `#f67300`,
            boxShadow: `#f67300 0px -2px 0px inset`,
          }}
        >
          Me
        </Link>
        <Link getProps={isActive} {...props}
          to="/blog"
          style={{
            textDecoration: `none`,
          }}
          activeStyle={{
            color: `#f67300`,
            boxShadow: `#f67300 0px -2px 0px inset`,
          }}
        >
          Blog
        </Link>
      </div>*/}
    </div>
  </header>
)

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : {}
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
